import 'babel-polyfill'

// styles
import './styles/main.scss'

// Sanity client & data service
import service from './service'

// scene renderer
import scene from './scene'

const init = async () => {
  // Get sketch name from URL
  const path = window.location.pathname.split('/')[1]

  // built-in scenes include prototypes and other sketches
  // they are runnable functions keyed by path name (aka. slug)
  const scenes = {
    // ...prototypes,
    helloWorld: () => {
      document.body.innerHTML = '<h2 style="margin: 2rem">Hello, World</h2>'
    }
  }

  // If built-in scene is found, run it
  if (typeof scenes[path] === 'function') {
    scenes[path]()
  } else {
    if (path) {
      try {
        // Otherwise, look for scene config in the CMS
        const sceneConfig = await service.getSceneForPath(path)

        // If found, create and run scene using this config
        if (sceneConfig) {
        
          // Set the full-screen overlay to the curtain color and set the overlay opacity to 1.
          let colors = sceneConfig.curtainColor;
          document.getElementById("loading-overlay").style.backgroundColor = `rgb(${colors[0]}, ${colors[1]}, ${colors[2]})`; 
          document.getElementById("loading-overlay").style.opacity = "1"; 

          // Load and initialize the scene
          await scene(sceneConfig)();

          // Once the scene has loaded, set the opacity back to 0 to fade the color overlay out and reveal the scene.
          document.getElementById("loading-overlay").style.opacity = "0"; 
        }

      } catch (e) {
        console.log(e)
      }
    }
  }
}

if (typeof window !== 'undefined') window.onload = init
