/* eslint-disable no-undef */
// import process from 'process'
import dat from 'dat.gui'
import Stats from 'stats.js'
// import bodyPix from '@tensorflow-models/body-pix'
const bodyPix = require('@tensorflow-models/body-pix')

import {
  Application,
  BaseTexture,
  Container,
  Texture,
  RenderTexture,
  Sprite,
  filters,
  SpriteMaskFilter,
  BLEND_MODES,
  utils
} from 'pixi.js'

import { ColorReplaceFilter } from 'pixi-filters'
import * as StackBlur from 'stackblur-canvas'
import MediaStreamResource from './util/MediaStreamResource'
import { interpColor } from './util/interpolation'
import soundscape from './soundscape'
import tracker from './tracker'

const DEFAULT_CONFIG = {
  globalAlpha: 0.8,
  webcamAlpha: 0.75,
  backgroundAlpha: 0.85,
  bodyAlpha: 0.5,
  fillAlpha: 0.9,
  guideAlpha: 1,
  guideScale: 1,
  zoom: 1.1,
  cFade: 0.965,
  cAlpha: 0.4,
  initialBlur: 0.75,
  minPointScore: 0.2,
  mainVideoVolume: 1,
  guideVideoVolume: 0,
  // wristPositionEasingFactor: 0.15,
  // headPositionEasingFactor: 0.3,
  soundEasingFactor: 0.04,
  soundMaxDistance: 2,
  velocityFallOff: 0.85,
  trackerBufferLength: 4,
  velocityMultiplier: 0.165,
  velocityThreshold: 0.004,
  wristVelocityCurve: 2,
  headVelocityCurve: 2,
  showJointDebugSprites: false
}

export default (settings) => async () => {
  const DEV_MODE = process.env.NODE_ENV === 'development'
  const USE_EASED_POSITIONS = false // vs buffered/averaged position from tracker

  if (DEV_MODE) {
    console.log('Loading Scene', { settings })
  }

  let pos = 0

  // local vars
  let app,
    gui,
    stats,
    bodypix,
    ctx,
    maskTexture,
    maskOverlayTexture,
    webcamVideo,
    mainVideo,
    fillSprite,
    guideSprite,
    curtainSprite,
    pointVis,
    sounds,
    keypoints,
    dotImg

  // settings from JSON
  const {
    guideVideo,
    useVideoAsFill,
    fillColors,
    bodyColors,
    backgroundVideo,
    mobileVideo,
    curtainFadeDuration = 10,
    curtainColor
  } = settings

  const bodypixOptions = {
    // architecture: 'MobileNetV1',
    // outputStride: 16,
    // segmentationThreshold: 0.4,
    // multiplier: 0.75,
    // quantBytes: 4,
    modelUrl: '/bodypix/mobilenet_float_075_model-stride16.json'
  }

  // runtime configuration
  const config = {
    ...DEFAULT_CONFIG,
    ...settings
  }

  // stage size
  const SCALE = 1
  const W = 1280 * SCALE
  const H = 720 * SCALE

  // capture scale
  const CSCALE = 0.5
  const CW = W * CSCALE
  const CH = H * CSCALE

  const GUIDE_SIZE = 512 * SCALE

  const getCtx = (w = CW, h = CH, className = null) => {
    const ctx = document.createElement('canvas').getContext('2d')
    ctx.canvas.width = w
    ctx.canvas.height = h
    if (className) ctx.canvas.className = className
    return ctx
  }

  const trackedPoints = {
    rightWrist: {
      velocity: 0,
      easingFactor: 0.15,
      target: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
      homePosition: { x: 1.1, y: 0.6 },
      sprite: null,
      score: 0
    },
    leftWrist: {
      velocity: 0,
      easingFactor: 0.15,
      target: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
      homePosition: { x: -0.1, y: 0.6 },
      sprite: null,
      score: 0
    },
    nose: {
      velocity: 0,
      easingFactor: 0.3,
      target: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
      homePosition: { x: 0.5, y: -0.1 },
      sprite: null,
      score: 0
    }
  }

  // setup
  const setup = async () => {
    // check screensize
    if (document.body.clientWidth <= 1024) {
      playFallbackVideo('Need larger screen')
      return
    }

    // Get webcam
    let capture

    try {
      // This will fail in insecure contexts (non-HTTPS other than localhost) or when webcam is blocked by user
      capture = await navigator.mediaDevices.getUserMedia({
        video: { width: CW, height: CH }
      })
    } catch (e) {
      console.log(e)
      // webcam not available
      playFallbackVideo('Webcam not available')
      return
    }

    app = new Application({
      antialias: true,
      width: W,
      height: H,
      transparent: false,
      backgroundColor: 0xcccccc
    })

    app.stage.interactive = true

    // MediaStream wraps Video and allows us to pass in the srcObject (instead of relying on 'src')
    const captureRes = new MediaStreamResource(capture)
    webcamVideo = captureRes.source
    webcamVideo.width = CW
    webcamVideo.height = CH

    // We have webcam
    document.body.className = 'loading'

    // load bodyPix
    bodypix = await bodyPix.load(bodypixOptions)

    // Webcam sprite with some blur
    const captureSprite = new Sprite(new Texture(new BaseTexture(captureRes, { mipmap: false })))
    captureSprite.filters = [new filters.BlurFilter(16)]
    // captureSprite.blendMode = BLEND_MODES[config.backgroundBlendMode]

    // Flip the capture sprite (for mirror)
    captureSprite.anchor.x = 1
    captureSprite.scale.x = -1

    // Canvas and Textures for Mask data
    ctx = {
      mask: getCtx(),
      buffer: getCtx(),
      temp: getCtx(),
      leftActivity: getCtx(),
      rightActivity: getCtx(),
      headActivity: getCtx(),

      // tiny
      l1: getCtx(1, 1, 'tiny'),
      r1: getCtx(1, 1, 'tiny'),
      l2: getCtx(1, 1, 'tiny'),
      r2: getCtx(1, 1, 'tiny'),
      h: getCtx(1, 1, 'tiny'),
      t: getCtx(1, 1, 'tiny')
    }

    // dot image
    dotImg = getDotImage()

    const fillColorFilter = new ColorReplaceFilter(0xffffff, 0xff0000)
    const bodyColorFilter = new ColorReplaceFilter(0xffffff, 0xff0000)

    maskTexture = new Texture.from(ctx.buffer.canvas)
    maskOverlayTexture = new Texture.from(ctx.mask.canvas)

    // Mask Sprite (flipped for mirror)
    const maskSprite = new Sprite(maskTexture)
    maskSprite.anchor.x = 1
    maskSprite.scale.x = -1

    const bodySprite = new Sprite(maskOverlayTexture)
    bodySprite.anchor.x = 1
    bodySprite.scale.x = -1
    bodySprite.filters = [bodyColorFilter]

    let backgroundSprite, guideVideoEl

    // Fill (video or colors)
    if (useVideoAsFill) {
      fillSprite = new Sprite(Texture.from(backgroundVideo))
      mainVideo = fillSprite.texture.baseTexture.resource.source

      // sprite mask filter
      maskSprite.renderable = false
      const maskFilter = new SpriteMaskFilter(maskSprite)
      fillSprite.filters = [maskFilter]

      // Apply background blend mode to the captureSprite which we
      // will place higher in the stacking order
      captureSprite.blendMode = BLEND_MODES[config.backgroundBlendMode]

      // using background colors
      backgroundSprite = Sprite.from(Texture.WHITE)
      // backgroundSprite.beginFill(0xffffff)
      // backgroundSprite.drawRect(0, 0, 16, 16)
      // backgroundSprite.endFill()
      backgroundSprite.filters = [fillColorFilter]
    } else {
      // Background Video Sprite
      backgroundSprite = new Sprite(Texture.from(backgroundVideo))
      mainVideo = backgroundSprite.texture.baseTexture.resource.source

      // Use background blend mode defined in settings
      backgroundSprite.blendMode = BLEND_MODES[config.backgroundBlendMode]

      // using fill colors - use the mask sprite as the blob for color fill (no need for mask)
      fillSprite = maskSprite

      // Use color replace filter for dynamic fill color
      fillSprite.filters = [fillColorFilter]
    }

    // setup the main video
    mainVideo.loop = false
    mainVideo.playsInline = true
    mainVideo.autoplay = true

    // mainVideo.playbackRate = 10

    // Guide Video
    if (guideVideo) {
      guideSprite = new Sprite(Texture.from(guideVideo))
      guideVideoEl = guideSprite.texture.baseTexture.resource.source
      guideVideoEl.loop = false
      guideVideoEl.playsInline = true
      guideVideoEl.autoplay = true
      guideSprite.width = GUIDE_SIZE
      guideSprite.height = GUIDE_SIZE
      guideSprite.x = W - GUIDE_SIZE
      guideSprite.y = 0
      guideSprite.blendMode = BLEND_MODES[config.guideBlendMode]
    }

    // create two render textures... these dynamic textures will be used to draw the scene into itself
    let renderTexture = RenderTexture.create(app.screen.width, app.screen.height)
    let renderTexture2 = RenderTexture.create(app.screen.width, app.screen.height)
    const currentTexture = renderTexture

    // create a new sprite that uses the render texture we created above
    const outputSprite = new Sprite(currentTexture)

    // size the full-screen overlay sprites
    ;[captureSprite, backgroundSprite, fillSprite, maskSprite, bodySprite].forEach((s) => {
      if (s) {
        s.width = W
        s.height = H
      }
    })

    // update alphas
    const A = config.globalAlpha
    guideSprite.alpha = config.guideAlpha * A
    fillSprite.alpha = config.fillAlpha * A
    captureSprite.alpha = config.webcamAlpha * A
    backgroundSprite.alpha = config.backgroundAlpha * A
    bodySprite.alpha = config.bodyAlpha * A

    app.stage.addChild(outputSprite)
    app.stage.addChild(captureSprite)
    app.stage.addChild(backgroundSprite)
    app.stage.addChild(fillSprite)

    // // webcam in background unless we need to overlay masked fillVideo which can't have blend mode
    if (useVideoAsFill) {
      app.stage.addChild(captureSprite)
    }
    app.stage.addChild(maskSprite)
    app.stage.addChild(bodySprite)
    app.stage.addChild(guideSprite)

    // Add pose keypoint visualization layer
    pointVis = new Container()
    pointVis.alpha = 0.5
    app.stage.addChild(pointVis)

    if (config.showJointDebugSprites) {
      for (var pointKey in trackedPoints) {
        let sprite = getPointSprite(pointKey)
        pointVis.addChild(sprite)
        trackedPoints[pointKey].sprite = sprite
      }
    }

    // show app
    document.querySelector('.content').appendChild(app.view)
    document.querySelector('.content').addEventListener('dblclick', toggleFullScreen)

    // run the segmentation on the video, handle the results in a callback
    // bodypix.segment(onSegment)
    segmentationLoop()

    const startTime = +new Date()
    let previousFrameTime = startTime
    let timeRemaining = 1000000000
    // let nextTick = 1

    curtainSprite = new Sprite(Texture.WHITE)
    curtainSprite.width = W
    curtainSprite.height = H
    curtainSprite.tint = utils.rgb2hex(curtainColor.map((c) => c / 255))
    curtainSprite.alpha = 0

    app.stage.addChild(curtainSprite)

    // console.log({
    //   fillColors: fillColors.map((fc) => utils.hex2string(utils.rgb2hex(fc.map((c) => c / 255)))),
    //   bodyColors: bodyColors.map((fc) => utils.hex2string(utils.rgb2hex(fc.map((c) => c / 255))))
    // })

    // Loop
    app.ticker.add(() => {
      if (stats) stats.begin()

      try {
        const { currentTime, duration } = mainVideo
        pos = (currentTime || 0) / (duration || 1)
        timeRemaining = duration - currentTime
      } catch (e) {
        console.log(e)
      }

      if (fillColorFilter && fillColors && fillColors.length) {
        fillColorFilter.newColor = utils.rgb2hex(interpColor(pos, fillColors).map((c) => c / 255))
      }

      if (bodyColors && bodyColors.length) {
        bodyColorFilter.newColor = utils.rgb2hex(interpColor(pos, bodyColors).map((c) => c / 255))
      }

      const nowTime = +new Date()
      const t = (nowTime - startTime) / 1000

      let deltaTime = Math.max(0, t - previousFrameTime)
      previousFrameTime = t

      // Fade to the curtainFillColor at the end of the experience.
      if (timeRemaining < curtainFadeDuration) {
        let increment = deltaTime / curtainFadeDuration
        curtainSprite.alpha = Math.min(1, curtainSprite.alpha + increment)
      }

      // const tps = 1
      // let tick = false
      // if (t * tps > nextTick * tps) {
      //   nextTick = Math.ceil(t * tps) / tps
      //   tick = true
      // }

      // update alphas (only if using gui)
      if (gui) {
        const A = config.globalAlpha
        guideSprite.alpha = config.guideAlpha * A
        fillSprite.alpha = config.fillAlpha * A
        captureSprite.alpha = config.webcamAlpha * A
        backgroundSprite.alpha = config.backgroundAlpha * A
        bodySprite.alpha = config.bodyAlpha * A

        const gs = GUIDE_SIZE * config.guideScale
        guideSprite.width = gs
        guideSprite.height = gs
        guideSprite.x = W - gs
      }

      // update other dynamic values
      mainVideo.volume = config.mainVideoVolume
      guideVideoEl.volume = config.guideVideoVolume

      tracker.maxBufferLength = config.trackerBufferLength

      if (sounds) {
        sounds.easingFactor = config.soundEasingFactor
        sounds.maxDistance = config.soundMaxDistance
        sounds.wristVelocityCurve = config.wristVelocityCurve
        sounds.headVelocityCurve = config.headVelocityCurve
      }

      // log keypoints
      if (keypoints) {
        keypoints.forEach(({ part, position: { x, y }, score }) => {
          if (trackedPoints[part]) {
            const point = trackedPoints[part]
            point.prev = { ...trackedPoints[part].target }
            point.score = score
            if (score > config.minPointScore) {
              point.target.x = 1 - x / CW
              point.target.y = y / CH
              // const dx = point.target.x - point.prev.x
              // const dy = point.target.y - point.prev.y
              // point.velocity = Math.min(1, Math.sqrt(dx * dx + dy * dy) * 1000)
            } else {
              // move off the screen
              // NOTE: disabling this behavior for now, seeking back to a home position causes jerky
              //       movement when tracking quality is poor.
              //  point.target = { ...point.homePosition }
              // point.velocity = 0
            }

            // Update eased tracked joints/body parts.
            // ease toward the target point
            let newX = point.position.x + (point.target.x - point.position.x) * point.easingFactor
            let newY = point.position.y + (point.target.y - point.position.y) * point.easingFactor

            if (deltaTime > 0) {
              // fall-off
              point.velocity *= config.velocityFallOff

              let deltaX = newX - point.position.x
              let deltaY = newY - point.position.y

              var linearVelocity = Math.sqrt(deltaX * deltaX + deltaY * deltaY)

              // If the velocity is above a certain amount, contribute it to the linear velocity value.
              if (linearVelocity > config.velocityThreshold) {
                point.velocity += (config.velocityMultiplier * linearVelocity) / deltaTime
              }

              // clamp
              point.velocity = Math.min(1, point.velocity)
            }

            point.position.x = newX
            point.position.y = newY

            tracker.log(part, {
              x: point.target.x,
              y: point.target.y,
              score,
              velocity: point.velocity
            })
          }

          // draw head and wrists onto their own canvases
          if (dotImg) {
            const r = dotImg.width / 2

            // inflate scores as we map to opacity
            const alpha = Math.pow(score, 0.5) * config.cAlpha

            const z = -1.2
            const f = 0.97

            if (part == 'leftWrist') {
              zoomBuffer(ctx.leftActivity, ctx.temp, z, f, 0, 0)
              ctx.leftActivity.globalAlpha = alpha
              ctx.leftActivity.drawImage(dotImg, x - r, y - r)
            }

            if (part == 'rightWrist') {
              zoomBuffer(ctx.rightActivity, ctx.temp, z, f, 0, 0)
              ctx.rightActivity.globalAlpha = alpha
              ctx.rightActivity.drawImage(dotImg, x - r, y - r)
            }

            if (part == 'nose') {
              zoomBuffer(ctx.headActivity, ctx.temp, 2, f, 0, 0)
              ctx.headActivity.globalAlpha = alpha
              ctx.headActivity.drawImage(dotImg, x - r, y - r)
            }
          }
        })
      }

      // Update left and right masks (scale down to get single pixel value "activity level")
      const px = 1
      ctx.l2.clearRect(0, 0, px, px)
      ctx.r2.clearRect(0, 0, px, px)
      ctx.h.clearRect(0, 0, px, px)

      // left and right halves of the canvases (intentionally flipped)
      ctx.r2.drawImage(ctx.rightActivity.canvas, 0, 0, CW * 0.66, CH, 0, 0, px, px)
      ctx.l2.drawImage(ctx.leftActivity.canvas, CW * 0.33, 0, CW * 0.66, CH, 0, 0, px, px)

      // top half for the head
      ctx.h.drawImage(ctx.headActivity.canvas, 0, 0, CW, CH * 0.66, 0, 0, px, px)

      // // add trails to body canvas
      zoomBuffer(ctx.buffer, ctx.temp, config.zoom)

      // Draw latest blurred segmentation blob onto our canvas
      ctx.buffer.globalAlpha = config.cAlpha
      ctx.buffer.drawImage(ctx.mask.canvas, 0, 0)

      // draw left and right activity blobs
      ctx.buffer.globalAlpha = 0.1
      ctx.buffer.drawImage(ctx.leftActivity.canvas, 0, 0)
      ctx.buffer.drawImage(ctx.rightActivity.canvas, 0, 0)

      // Tell pixi mask texture to update
      maskTexture.update()

      // swap the main buffers
      const temp = renderTexture
      renderTexture = renderTexture2
      renderTexture2 = temp
      outputSprite.texture = renderTexture

      // render the stage to the texture
      app.renderer.render(app.stage, renderTexture2, false)

      // convert image data to activity level
      const imageDataToActivity = (id, exp = 0.3, whiteBalance = 0.5) =>
        Math.pow(Math.max(0, Math.min(1, id.data[3] / (255 * whiteBalance))), exp)

      // Process sound triggers
      if (sounds) {
        sounds.set({
          // Set "activity" levels using image data
          activityLeft: imageDataToActivity(ctx.l2.getImageData(0, 0, px, px), 0.3, 0.25),
          activityRight: imageDataToActivity(ctx.r2.getImageData(0, 0, px, px), 0.3, 0.25),
          activityHead: imageDataToActivity(ctx.h.getImageData(0, 0, px, px), 2, 0.7)
        })

        sounds.set({
          // smooth velocity by pulling from tracker
          velocityLeft: (tracker.stats.leftWrist && tracker.stats.leftWrist.velocity.avg) || 0,
          velocityRight: (tracker.stats.rightWrist && tracker.stats.rightWrist.velocity.avg) || 0,
          velocityHead: (tracker.stats.nose && tracker.stats.nose.velocity.avg) || 0
        })

        if (USE_EASED_POSITIONS) {
          // Send Ben's tweened position to the soundscape
          sounds.set({
            xPosLeft: trackedPoints.leftWrist.position.x,
            yPosLeft: trackedPoints.leftWrist.position.y,
            xPosRight: trackedPoints.rightWrist.position.x,
            yPosRight: trackedPoints.rightWrist.position.y
          })

          if (sounds && config.showJointDebugSprites) {
            for (let part in trackedPoints) {
              const point = trackedPoints[part]
              // Update debug sprite positions to match current tweened position we are sending to the soundscape
              if (point.sprite) {
                point.sprite.x = trackedPoints[part].position.x * W
                point.sprite.y = trackedPoints[part].position.y * H
                point.sprite.alpha = point.score
              }
            }
          }
        } else if (tracker.stats.leftWrist && tracker.stats.rightWrist) {
          // Send target position data smoothed by the tracker/buffer
          // This is the moving average (last 8 frames or so [see config in tracker])
          sounds.set({
            xPosLeft: tracker.stats.leftWrist.x.avg,
            yPosLeft: tracker.stats.leftWrist.y.avg,
            xPosRight: tracker.stats.rightWrist.x.avg,
            yPosRight: tracker.stats.rightWrist.y.avg
          })

          if (sounds && config.showJointDebugSprites) {
            for (let part in trackedPoints) {
              const point = trackedPoints[part]
              // Update debug sprite positions to match the moving average we are sending to the soundscape
              if (point.sprite) {
                point.sprite.x = tracker.stats[part].x.avg * W
                point.sprite.y = tracker.stats[part].y.avg * H
                point.sprite.alpha = point.score
              }
            }
          }
        }
      }

      if (stats) stats.end()
    })

    document.body.className = 'loaded'

    if (settings.sounds) {
      sounds = soundscape(settings.sounds)
      sounds.start()
    }

    if (DEV_MODE) initGUI()
  }

  async function segmentationLoop() {
    try {
      const segmentation = await bodypix.segmentPerson(webcamVideo, {
        internalResolution: 'medium',
        segmentationThreshold: 0.4
      })

      if (maskOverlayTexture && ctx.mask) {
        const foregroundColor = { r: 255, g: 255, b: 255, a: 255 }
        const backgroundColor = { r: 0, g: 0, b: 0, a: 0 }
        const mask = bodyPix.toMask(segmentation, foregroundColor, backgroundColor, true)
        const blur = (32 * config.initialBlur) >> 0
        StackBlur.imageDataRGBA(mask, 0, 0, CW, CH, blur)
        ctx.mask.putImageData(mask, 0, 0)
        maskOverlayTexture.update()
      }

      onPose(segmentation.allPoses)
    } catch (e) {}

    requestAnimationFrame(segmentationLoop)
  }

  const pointSprites = {}
  // window.pointSprites = pointSprites

  function getPointSprite(key) {
    const size = W / 16
    // use curtain color for overlay
    // const img = getDotImage(size, `rgb(${curtainColor})`)
    const img = getDotImage(size, '#fff', 0.125)
    if (!pointSprites[key]) {
      const s = Sprite.from(Texture.from(img))
      s.width = size
      s.height = size
      s.anchor.set(0.5)
      pointVis.addChild(s)
      pointSprites[key] = s
    }
    return pointSprites[key]
  }

  function onPose(poses) {
    if (!poses || !poses.length) return
    const pose = poses[0]
    keypoints = pose.keypoints
    keypoints._timestamp = +new Date()
  }

  const initGUI = () => {
    gui = new dat.GUI({ width: 320, autoPlace: true })
    gui.domElement.id = 'gui'

    stats = new Stats()
    stats.domElement.style.position = 'static'
    ;[].forEach.call(stats.domElement.children, (child) => (child.style.display = ''))

    // gui.add(bodypixOptions, 'segmentationThreshold', 0.01, 0.99)
    let folder = gui.addFolder('Layers')
    folder.add(config, 'globalAlpha', 0.01, 1)
    folder.add(config, 'webcamAlpha', 0.01, 1)
    folder.add(config, 'backgroundAlpha', 0.01, 1)
    folder.add(config, 'fillAlpha', 0.01, 1)
    folder.add(config, 'bodyAlpha', 0.01, 1)
    folder.add(config, 'guideAlpha', 0.01, 1)
    // folder.open()

    if (sounds) {
      folder = gui.addFolder('Audio Triggers')
      folder.add(sounds.state, 'activityHead', 0, 1).listen()
      folder.add(sounds.state, 'activityLeft', 0, 1).listen()
      folder.add(sounds.state, 'activityRight', 0, 1).listen()
      folder.add(sounds.state, 'velocityHead', 0, 1).listen()
      folder.add(sounds.state, 'velocityLeft', 0, 1).listen()
      folder.add(sounds.state, 'velocityRight', 0, 1).listen()
      // folder.add(sounds.state, 'xPosLeft', 0, 1).listen()
      // folder.add(sounds.state, 'yPosLeft', 0, 1).listen()
      // folder.add(sounds.state, 'xPosRight', 0, 1).listen()
      // folder.add(sounds.state, 'yPosRight', 0, 1).listen()
      // folder.open()
    }

    folder = gui.addFolder('Sound Controls')
    folder.add(config, 'mainVideoVolume', 0, 1)

    if (sounds) {
      folder.add(config, 'trackerBufferLength', 0, 20)
      folder.add(config, 'velocityMultiplier', 0.01, 0.2)
      folder.add(config, 'velocityThreshold', 0.001, 0.02)
      folder.add(config, 'velocityFallOff', 0.01, 0.99)
      folder.add(config, 'soundEasingFactor', 0.01, 1)
      folder.add(config, 'soundMaxDistance', 2, 100)
      folder.add(config, 'wristVelocityCurve', 0.1, 10)
      folder.add(config, 'headVelocityCurve', 0.1, 10)
    }

    folder.open()

    folder = gui.addFolder('FX')
    folder.add(config, 'cFade', 0.9, 0.999)
    folder.add(config, 'cAlpha', 0, 1)
    folder.add(config, 'zoom', -2, 2)
    // gui.add(config, 'zoomSpeed', 0, 1)
    folder.add(config, 'initialBlur', 0.125, 2)
    folder.add(config, 'minPointScore', 0, 1)
    // gui.add(config, 'backgroundBlur', 0, 1)
    // folder.open()

    folder = gui.addFolder('Masks')
    const canvasLi = document.createElement('li')
    canvasLi.appendChild(ctx.mask.canvas)
    canvasLi.appendChild(ctx.buffer.canvas)
    // canvasLi.appendChild(dotImg)
    canvasLi.appendChild(ctx.leftActivity.canvas)
    canvasLi.appendChild(ctx.rightActivity.canvas)
    canvasLi.appendChild(ctx.headActivity.canvas)

    // canvasLi.appendChild(ctx.l1.canvas)
    // canvasLi.appendChild(ctx.r1.canvas)
    canvasLi.appendChild(ctx.l2.canvas)
    canvasLi.appendChild(ctx.r2.canvas)
    canvasLi.className = 'gui-canvas'
    folder.__ul.appendChild(canvasLi)
    // folder.open()

    folder = gui.addFolder('Stats')
    const perfLi = document.createElement('li')
    perfLi.appendChild(stats.domElement)
    perfLi.className = 'gui-stats'
    folder.__ul.appendChild(perfLi)
    // folder.open()

    gui.close()
  }

  function drawCircle(context, centerX, centerY, radius, fillStyle = 'white') {
    context.beginPath()
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
    context.fillStyle = fillStyle
    context.fill()
  }

  function toggleFullScreen() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function zoomBuffer(bctx, tctx, zoom = config.zoom, fade = config.cFade, tx = 0, ty = 0) {
    const w = bctx.canvas.width
    const h = bctx.canvas.height
    const z = 1 + 0.01 * zoom
    // save last frame to temporary canvas
    tctx.clearRect(0, 0, w, h)
    tctx.drawImage(bctx.canvas, 0, 0)
    bctx.clearRect(0, 0, w, h)

    // draw last frame back to clean buffer, but zoomed slightly
    bctx.save()
    bctx.translate(w / 2, h / 2)
    bctx.scale(z, z)
    bctx.translate(-w / 2 + tx, -h / 2 + ty)

    // draw last frame at less than 100% for trails that fade off
    bctx.globalAlpha = fade
    bctx.drawImage(tctx.canvas, 0, 0)
    bctx.restore()
  }

  function getDotImage(size = 160, color = 'rgba(255, 255, 255, 0.25)', blurFactor = 0.25) {
    // const size = 160
    const center = size / 2
    const blur = (size * blurFactor) >> 0
    const ctx = getCtx(size, size, 'dot')
    drawCircle(ctx, center, center, size * 0.25, color)
    const imageData = ctx.getImageData(0, 0, size, size)
    StackBlur.imageDataRGBA(imageData, 0, 0, size, size, blur)
    ctx.putImageData(imageData, 0, 0)
    return ctx.canvas
  }

  function playFallbackVideo(msg) {
    console.log(`Playing fallback video. Reason: ${msg}`, mobileVideo)
    const contentEl = document.querySelector('.content')
    const videoEl = document.createElement('video')
    // videoEl.autoplay = true
    videoEl.playsInline = true
    videoEl.src = mobileVideo
    videoEl.className = 'fallback-video'
    contentEl.appendChild(videoEl)
    document.body.className = 'loaded fallback'
    document.body.style.backgroundColor = 'rgba(' + curtainColor.map((c) => c / 3) + ')'
    const btn = document.createElement('button')
    btn.clasNme = 'start-btn'
    btn.innerText = 'Start'
    btn.onclick = () => {
      videoEl.play()
      contentEl.removeChild(btn)
    }
    contentEl.appendChild(btn)
  }

  await setup()
}
